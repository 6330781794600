<template>
  <div class="container" id="content">
   <TableBox
      ref="tableBox"
      :tableHeader="tableHeader"
      :rowBtns="rowBtns"
      request="/leaveMsg/records"
      :isPaging="false"
      :addBtn="false"
      :tableParams="tableParams"
    ></TableBox>

    <ContentModal
      :visiable="contentVisiable"
      @handlerClose="closeModal"
      :record="detailData"
    ></ContentModal>
  </div>
</template>
<script>
import ContentModal from "./modal/content.vue";
import TableBox from "@/components/lxw/TableBox/index.vue";

export default {
  name: "lawsAReg",
  components: { ContentModal, TableBox },
  data() {
    return {
      loading: false,
      tableParams: {
        num: 1000000
      },

      usetHeader: [
        {
          label: "用户名称",
          key: "real_name",
        },
        /*{
          label: "用户账号",
          key: "user_name",
        },*/
      ],
      expertHeader: [
        {
          label: "专家名称",
          key: "real_name",
        },
        /*{
          label: "专家账号",
          key: "user_name",
        },*/
      ],

      tableHeader: [],
      rowBtns: [

        {
          label: "沟通",
          type: "primary",
          size: "small",
          icon: "el-icon-s-comment",
          handler: (row) => {
            this.content(row);
          },
        },
      ],

      contentVisiable: false,
      token: "",
      detailData: {},
      idflag: ''
    };
  },
  created(){
    this.idflag = window.localStorage.getItem('label')
    if(this.idflag == 0){ // 用户
      this.tableHeader = this.expertHeader
    }else if(this.idflag == 1){ // 专家
      this.tableHeader = this.usetHeader
    }
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));

  },
  methods: {
    // 咨询
    content(row){
      this.detailData = row
      this.contentVisiable = true;
    },
    
    closeModal() {
      this.contentVisiable = false;
    },
  },
};
</script>

<style scoped>
</style>
